import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';

const ModalView = ({
  header,
  children,
  trigger,
  actions,
  onOpen,
  onClose,
  open = false,
  isControlled,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const triggerModal = () => setModalOpen((isOpen) => !isOpen);
  return (
    <Modal
      trigger={trigger && trigger(triggerModal)}
      open={isControlled ? open : modalOpen}
      onOpen={onOpen}
      onClose={onClose}
      closeOnDimmerClick={false}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Modal.Description>{children}</Modal.Description>
      </Modal.Content>
      {actions && <Modal.Actions>{actions(triggerModal)}</Modal.Actions>}
    </Modal>
  );
};

export default ModalView;
