import { createSelector } from 'reselect';

import sectionTypes from '../../constants/sectionTypes';

// Helper Functions
const isPage = type =>
  type === sectionTypes['Page (Image or CSV)'] ||
  type === sectionTypes.Url ||
  type === sectionTypes['Page (PDF)'] ||
  type === sectionTypes['Page (Video)'] ||
  type === sectionTypes['Page (Searchable Image)'];

const findChildren = (sections, childrenSectionList) =>
  childrenSectionList
    .split(',')
    .reduce(
      (acc, childSectionId) => [
        ...acc,
        ...sections.filter(s => parseInt(s.id, 10) === parseInt(childSectionId, 10)),
      ],
      [],
    );

const findChildrenRecursively = (listOfSectionsArray, childrenSectionsList) =>
  childrenSectionsList
    .split(',')
    .reduce(
      (acc, childSectionId) => [
        ...acc,
        ...listOfSectionsArray.filter(s => parseInt(s.id, 10) === parseInt(childSectionId, 10)),
      ],
      [],
    )
    .map(rs => {
      const children =
        rs.childrenSections != null
          ? findChildrenRecursively(listOfSectionsArray, rs.childrenSections)
          : [];
      return {
        id: rs.id,
        name: rs.name,
        parentSections: rs.parentSections,
        children,
        numChildren: children.length,
        type: rs.type,
      };
    });

// Selectors
const bookSelector = state => state.api.books;

export const selectBookById = createSelector(
  bookSelector,
  (_, id) => id,
  (books, id) => Object.values(books).find(item => item.id === parseInt(id, 10)),
);

export const selectBookName = createSelector(
  bookSelector,
  (_, id) => id,
  selectBookById,
  (_, __, book) => (book || {}).name,
);

const selectRootSections = createSelector(
  selectBookById,
  book => book && book.sections.filter(section => section.t === sectionTypes.Root),
);

export const selectSectionById = createSelector(
  selectBookById,
  (_, book, sectionId) => sectionId,
  (book, sectionId) => book && book.sections.find(section => section.i === sectionId),
);

export const selectSectionPages = createSelector(
  selectBookById,
  selectSectionById,
  (book, section) =>
    book &&
    book.pages &&
    section &&
    section.s &&
    section.s
      .split(',')
      .reduce(
        (acc, sectionId) => [
          ...acc,
          ...book.pages.filter(page => parseInt(page.id, 10) === parseInt(sectionId, 10)),
        ],
        [],
      )
      .map(
        ({
          id,
          bookId,
          name,
          url,
          docNo,
          revNo,
          pageType,
          config,
          parentSections,
          enabled,
          filename,
        }) => ({
          id,
          bookId,
          name,
          url,
          docNo,
          revNo,
          filename,
          pageType,
          config,
          parentSections,
          enabled,
        }),
      ),
);

export const selectSectionsChildren = createSelector(
  selectBookById,
  selectSectionById,
  (book, section) =>
    book &&
    book.sections &&
    section &&
    section.s &&
    section.s
      .split(',')
      .reduce(
        (acc, sectionId) => [
          ...acc,
          ...book.sections.filter(child => child.i === parseInt(sectionId, 10)),
        ],
        [],
      )
      .map(item => ({ id: item.i, name: item.n })),
);

const selectSwipeSections = createSelector(
  selectBookById,
  book =>
    book &&
    book.sections
      .filter(section => section.t === sectionTypes.Swipe)
      .map(s => ({ id: s.i, name: s.n, parentSections: s.ps, type: s.t, childrenSections: s.s })),
);

const selectRestSections = createSelector(
  selectBookById,
  book =>
    book &&
    book.sections
      .filter(
        section =>
          section.t === sectionTypes.List || section.t === sectionTypes.SectionWithListOfSections,
      )
      .map(s => ({ id: s.i, name: s.n, parentSections: s.ps, type: s.t, childrenSections: s.s })),
);

const selectPages = createSelector(
  selectBookById,
  book =>
    book &&
    book.sections
      .filter(section => isPage(section.t))
      .map(s => ({ id: s.i, name: s.n, parentSections: s.ps, type: s.t, childrenSections: s.s })),
);

export const selectSectionTree = createSelector(
  [selectRootSections, selectSwipeSections, selectRestSections, selectPages],
  (rootSections, swipeSections, listSections, pages) =>
    rootSections &&
    rootSections.map(rs => {
      const children =
        rs.s != null
          ? findChildren(swipeSections, rs.s).map(s => {
              const childs =
                s.childrenSections != null
                  ? findChildrenRecursively(
                      [...pages, ...swipeSections, ...listSections],
                      s.childrenSections,
                    )
                  : [];
              return {
                ...s,
                children: childs,
                numChildren: childs.length,
              };
            })
          : [];

      return {
        id: rs.i,
        name: rs.n,
        type: rs.t,
        children,
        numChildren: children.length,
      };
    }),
);

export const selectAllSections = createSelector(
  [selectRootSections, selectSwipeSections, selectRestSections, selectPages],
  (rootSections, swipeSections, listSections, pageSections) => {
    return [...rootSections, ...swipeSections, ...listSections, ...pageSections]
      .filter(s => s.id || s.i)
      .map(s => ({
        id: s.id || s.i,
        name: s.name || s.n,
      }));
  },
);

export const selectParentSectionsByName = createSelector(
  selectBookById,
  (_, __, parentSections) => parentSections,
  ({ sections }, parentSections) =>
    sections
      .filter(s =>
        (`${parentSections}` || '')
          .split(',')
          .map(sec => sec && parseInt(sec, 10))
          .includes(s.i),
      )
      .map(s => s.n),
);
