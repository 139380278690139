import React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';

const WarningModal = ({ open, sectionType, onClick }) => (
  <Modal open={open} basic size="small">
    <Header icon="delete" content="Change Section Type" />
    <Modal.Content>
      <p>
        {`There are pages related to this section with type: ${sectionType}. Please delete them before changing the section type.`}
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button basic inverted onClick={onClick}>
        OK
      </Button>
    </Modal.Actions>
  </Modal>
);

export default WarningModal;
