import React, { useEffect } from 'react';
import { Segment, Label, Form, Checkbox, Select, Button } from 'semantic-ui-react';
import { PageTypes } from '../../../constants/PageTypes';

const fileInputRef = React.createRef();

const SectionToPageTypes = {
  6: 2,
  7: 5,
  8: 6,
};

const imageOrCSV = {
  0: 'image/*',
  1: '.csv',
};

//dropdown 
const mapSectionPageTypes = sectionType => {
  switch (sectionType) {
    case 3:
      return [
        { key: 0, text: 'Image', value: 0 },
        { key: 1, text: 'CSV', value: 1 },
      ];
    case 4:
      return [{ key: 0, text: 'Url', value: 4 }];
    case 6:
      return [{ key: 0, text: 'PDF', value: SectionToPageTypes[6] }];
    case 7:
      return [{ key: 0, text: 'Video', value: SectionToPageTypes[7] }];
    case 8:
      return [{ key: 0, text: 'Searchable Image', value: SectionToPageTypes[8] }];
    default:
      return null;
  }
};

const PageInputFields = ({
  type,
  onInputChange,
  multipleInputChange,
  formFields,
  inputError = {},
  setInputError,
}) => {

  useEffect(() => {
    if (SectionToPageTypes[type])
      onInputChange({ name: 'pageType', value: SectionToPageTypes[type] });
  }, [type]);

  // this is the URL section type -> page type is fixed: URL=4
  if (type === 4) {
    formFields.pageType = 4;
  }

  switch (type) {
    case 0:
    case 1:
    case 2:
    case 3:
      return (
        <Segment.Group>
          <Segment basic>
            <Form>
              <Form.Group widths="equal">
                <Form.Field
                  width={5}
                  control={Select}
                  value={formFields.pageType}
                  options={mapSectionPageTypes(type)}
                  label={{ children: 'Page Type', htmlFor: 'form-select-control-sectiontype' }}
                  placeholder="Page Type"
                  onChange={(e, data) => {
                    setInputError({ ...inputError, pageType: null });
                    onInputChange({ name: 'pageType', value: data.value });
                  }}
                  required
                  error={inputError.pageType || null}
                />
                <Form.Input
                  width={5}
                  type="text"
                  label="Name"
                  placeholder="Name"
                  name="name"
                  onChange={(e, data) => {
                    setInputError({ ...inputError, name: null });
                    onInputChange(data);
                  }}
                  value={formFields.name || ''}
                  required
                  error={inputError.name || null}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  width={2}
                  type="text"
                  label="Doc No"
                  placeholder="Document number"
                  name="docNo"
                  onChange={(e, data) => {
                    onInputChange(data);
                  }}
                  value={formFields.docNo || ''}
                  disabled={formFields.pageType != PageTypes.IMAGE}
                />
                <Form.Input
                  width={1}
                  type="text"
                  label="Rev No"
                  placeholder="Revision number"
                  name="revNo"
                  onChange={(e, data) => {
                    onInputChange(data);
                  }}
                  value={formFields.revNo || ''}
                  disabled={formFields.pageType != PageTypes.IMAGE}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={8}
                  readOnly
                  type="text"
                  placeholder="Select a file to upload"
                  name="filename"
                  value={formFields.filename || ''}
                  onChange={(e, data) => {
                    setInputError({ ...inputError, filename: null });
                    onInputChange(data);
                  }}
                  error={inputError.filename || null}
                />
                <Form.Field width={8}>
                  <Button
                    icon="upload"
                    label={
                      <Label as="label" style={{ cursor: 'pointer' }} basic content="Select file" />
                    }
                    onClick={() => fileInputRef.current.click()}
                    labelPosition="right"
                  />
                  <input
                    accept={imageOrCSV[formFields.pageType] || 'image/*,.csv'}
                    ref={fileInputRef}
                    hidden
                    type="file"
                    name="file"
                    value=""
                    onChange={(e, data) => {
                      setInputError({ ...inputError, filename: null });
                      return (
                        e.target.files[0] &&
                        multipleInputChange([
                          { name: 'file', value: e.target.files[0] },
                          { name: 'filename', value: e.target.files[0].name },
                        ])
                      );
                    }}
                  />
                  {formFields.filename && (
                    <Button
                      icon="remove circle"
                      style={{ color: 'red' }}
                      onClick={() =>
                        multipleInputChange([
                          { name: 'file', value: null },
                          { name: 'filename', value: '' },
                        ])
                      }
                    />
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <Checkbox
                  label="Enabled"
                  onChange={(e, data) => onInputChange({ name: 'enabled', value: data.checked })}
                  checked={formFields.enabled}
                />
              </Form.Field>
            </Form>
          </Segment>
        </Segment.Group>
      );
    case 4:
      return (
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              width={8}
              label="Name"
              placeholder="Name"
              name="name"
              onChange={(e, data) => {
                setInputError({ ...inputError, name: null });
                onInputChange(data);
              }}
              value={formFields.name || ''}
              required
              error={inputError.name || null}
            />
            <Form.Input
              width={8}
              label="URL"
              placeholder="URL"
              name="url"
              onChange={(e, data) => {
                setInputError({ ...inputError, url: null });
                onInputChange(data);
              }}
              value={formFields.url || ''}
              required
              error={inputError.url || null}
            />
          </Form.Group>
          <Form.Field>
            <Checkbox
              label="Enabled"
              onChange={(e, data) => onInputChange({ name: 'enabled', value: data.checked })}
              checked={formFields.enabled}
            />
          </Form.Field>
        </Form>
      );
    case 6:
      return (
        <Segment.Group>
          <Segment basic>
            <Form>
              <Form.Group widths="equal">
                <Form.Field
                  width={1}
                  control={Select}
                  value={formFields.pageType}
                  options={mapSectionPageTypes(type)}
                  label={{ children: 'Page Type', htmlFor: 'form-select-control-sectiontype' }}
                  placeholder="Page Type"
                  onChange={(e, data) => {
                    setInputError({ ...inputError, pageType: null });
                    onInputChange({ name: 'pageType', value: data.value });
                  }}
                  required
                  error={inputError.pageType || null}
                />
                <Form.Input
                  width={4}
                  type="text"
                  label="Name"
                  placeholder="Name"
                  name="name"
                  onChange={(e, data) => {
                    setInputError({ ...inputError, name: null });
                    onInputChange(data);
                  }}
                  value={formFields.name || ''}
                  required
                  error={inputError.name || null}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  width={2}
                  type="text"
                  label="Doc No"
                  placeholder="Document number"
                  name="docNo"
                  onChange={(e, data) => {
                    onInputChange(data);
                  }}
                  value={formFields.docNo || ''}
                />
                <Form.Input
                  width={1}
                  type="text"
                  label="Rev No"
                  placeholder="Revision number"
                  name="revNo"
                  onChange={(e, data) => {
                    onInputChange(data);
                  }}
                  value={formFields.revNo || ''}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={8}
                  readOnly
                  type="text"
                  placeholder="Select a file to upload"
                  name="filename"
                  value={formFields.filename || ''}
                  onChange={(e, data) => {
                    setInputError({ ...inputError, filename: null });
                    onInputChange(data);
                  }}
                  error={inputError.filename || null}
                />
                <Form.Field width={8} error={formFields.filename || null}>
                  <Button
                    icon="upload"
                    label={
                      <Label as="label" style={{ cursor: 'pointer' }} basic content="Select file" />
                    }
                    onClick={() => fileInputRef.current.click()}
                    labelPosition="right"
                  />
                  <input
                    accept=".pdf"
                    ref={fileInputRef}
                    hidden
                    type="file"
                    name="file"
                    value=""
                    onChange={(e, data) => {
                      setInputError({ ...inputError, filename: null });
                      return (
                        e.target.files[0] &&
                        multipleInputChange([
                          { name: 'file', value: e.target.files[0] },
                          { name: 'filename', value: e.target.files[0].name },
                        ])
                      );
                    }}
                  />
                  {formFields.filename && (
                    <Button
                      icon="remove circle"
                      style={{ color: 'red' }}
                      onClick={() =>
                        multipleInputChange([
                          { name: 'file', value: null },
                          { name: 'filename', value: '' },
                        ])
                      }
                    />
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <Checkbox
                  label="Enabled"
                  onChange={(e, data) => onInputChange({ name: 'enabled', value: data.checked })}
                  checked={formFields.enabled}
                />
              </Form.Field>
            </Form>
          </Segment>
        </Segment.Group>
      );
    case 7:
      return (
        <Segment.Group>
          <Segment basic>
            <Form>
              <Form.Group widths="equal">
                <Form.Field
                  width={1}
                  control={Select}
                  value={formFields.pageType}
                  options={mapSectionPageTypes(type)}
                  label={{ children: 'Page Type', htmlFor: 'form-select-control-sectiontype' }}
                  placeholder="Page Type"
                  onChange={(e, data) => {
                    setInputError({ ...inputError, pageType: null });
                    onInputChange({ name: 'pageType', value: data.value });
                  }}
                  required
                  error={inputError.pageType || null}
                />
                <Form.Input
                  width={4}
                  type="text"
                  label="Name"
                  placeholder="Name"
                  name="name"
                  onChange={(e, data) => {
                    setInputError({ ...inputError, name: null });
                    onInputChange(data);
                  }}
                  value={formFields.name || ''}
                  required
                  error={inputError.name || null}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={8}
                  readOnly
                  type="text"
                  placeholder="Select a file to upload"
                  name="filename"
                  value={formFields.filename || ''}
                  onChange={(e, data) => {
                    setInputError({ ...inputError, filename: null });
                    onInputChange(data);
                  }}
                  error={inputError.filename || null}
                />
                <Form.Field width={8} error={formFields.filename || null}>
                  <Button
                    icon="upload"
                    label={
                      <Label as="label" style={{ cursor: 'pointer' }} basic content="Select file" />
                    }
                    onClick={() => fileInputRef.current.click()}
                    labelPosition="right"
                  />
                  <input
                    accept="video/*"
                    ref={fileInputRef}
                    hidden
                    type="file"
                    name="file"
                    value=""
                    onChange={(e, data) => {
                      setInputError({ ...inputError, filename: null });
                      return (
                        e.target.files[0] &&
                        multipleInputChange([
                          { name: 'file', value: e.target.files[0] },
                          { name: 'filename', value: e.target.files[0].name },
                        ])
                      );
                    }}
                  />
                  {formFields.filename && (
                    <Button
                      icon="remove circle"
                      style={{ color: 'red' }}
                      onClick={() =>
                        multipleInputChange([
                          { name: 'file', value: null },
                          { name: 'filename', value: '' },
                        ])
                      }
                    />
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <Checkbox
                  label="Enabled"
                  onChange={(e, data) => onInputChange({ name: 'enabled', value: data.checked })}
                  checked={formFields.enabled}
                />
              </Form.Field>
            </Form>
          </Segment>
        </Segment.Group>
      );
    case 8:
      return (
        <Segment.Group>
          <Segment basic>
            <Form>
              <Form.Group widths="equal">
                <Form.Field
                  width={1}
                  control={Select}
                  value={formFields.pageType}
                  options={mapSectionPageTypes(type)}
                  label={{ children: 'Page Type', htmlFor: 'form-select-control-sectiontype' }}
                  placeholder="Page Type"
                  onChange={(e, data) => {
                    setInputError({ ...inputError, pageType: null });
                    onInputChange({ name: 'pageType', value: data.value });
                  }}
                  required
                  error={inputError.pageType || null}
                />
                <Form.Input
                  width={4}
                  type="text"
                  label="Name"
                  placeholder="Name"
                  name="name"
                  onChange={(e, data) => {
                    setInputError({ ...inputError, name: null });
                    onInputChange(data);
                  }}
                  value={formFields.name || ''}
                  required
                  error={inputError.name || null}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  width={2}
                  type="text"
                  label="Doc No"
                  placeholder="Document number"
                  name="docNo"
                  onChange={(e, data) => {
                    onInputChange(data);
                  }}
                  value={formFields.docNo || ''}
                />
                <Form.Input
                  width={1}
                  type="text"
                  label="Rev No"
                  placeholder="Revision number"
                  name="revNo"
                  onChange={(e, data) => {
                    onInputChange(data);
                  }}
                  value={formFields.revNo || ''}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={8}
                  readOnly
                  type="text"
                  placeholder="Select a file to upload"
                  name="filename"
                  value={formFields.filename || ''}
                  onChange={(e, data) => {
                    setInputError({ ...inputError, filename: null });
                    onInputChange(data);
                  }}
                  error={inputError.filename || null}
                />
                <Form.Field width={8} error={formFields.filename || null}>
                  <Button
                    icon="upload"
                    label={
                      <Label as="label" style={{ cursor: 'pointer' }} basic content="Select file" />
                    }
                    onClick={() => fileInputRef.current.click()}
                    labelPosition="right"
                  />
                  <input
                    accept=".pdf"
                    ref={fileInputRef}
                    hidden
                    type="file"
                    name="file"
                    value=""
                    onChange={(e, data) => {
                      setInputError({ ...inputError, filename: null });
                      return (
                        e.target.files[0] &&
                        multipleInputChange([
                          { name: 'file', value: e.target.files[0] },
                          { name: 'filename', value: e.target.files[0].name },
                        ])
                      );
                    }}
                  />
                  {formFields.filename && (
                    <Button
                      icon="remove circle"
                      style={{ color: 'red' }}
                      onClick={() =>
                        multipleInputChange([
                          { name: 'file', value: null },
                          { name: 'filename', value: '' },
                        ])
                      }
                    />
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <Checkbox
                  label="Enabled"
                  onChange={(e, data) => onInputChange({ name: 'enabled', value: data.checked })}
                  checked={formFields.enabled}
                />
              </Form.Field>
            </Form>
          </Segment>
        </Segment.Group>
      );
    case 5:
      return null;
    default:
      return null;
  }
};

export default PageInputFields;
