import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import MainLayout from '../../layout/MainLayout';
import GridView from '../../general/GridView';

import {
  getBooks,
  createBook,
  deleteBook,
  getCompanies,
  editBook,
} from '../../../redux/api/actions';
import { showMessage, hideMessage } from '../../../redux/ui/actions';
import CreateBookModal from './CreateBookModal';
import DeleteBookModal from './DeleteBookModal';

const BooksPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const books = useSelector((state) => Object.values(state.api.books));
  const companies = useSelector((state) => Object.values(state.api.companies));
  const [loading, setLoading] = useState(false);
  const onClick = useCallback((id) => history.push(`/books/${id}`), [history]);

  const [showModal, setShowModal] = useState(false);
  const [editingBook, setEditingBook] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bookToDelete, setBookToDelete] = useState({});
  const triggerModal = useCallback(() => setShowModal((show) => !show), []);
  const triggerDeleteModal = useCallback(() => setShowDeleteModal((show) => !show), []);

  const onDelete = (bookD) => {
    triggerDeleteModal();
    setBookToDelete(bookD);
  };

  const saveBook = useCallback(
    (book) => {
      setLoading(true);
      triggerModal();
      dispatch(book.id ? editBook(book.id, book) : createBook(book))
        .then(() => dispatch(showMessage('Book saved successfully', 'positive')))
        .catch((e) => {
          dispatch(showMessage(e, 'negative'));
          setTimeout(() => dispatch(hideMessage()), 2000);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, triggerModal],
  );

  const removeBook = useCallback(
    (id) => {
      setLoading(true);
      triggerDeleteModal();
      dispatch(deleteBook(id))
        .then(() => dispatch(showMessage('Book deleted successfully', 'positive')))
        .catch((e) => {
          dispatch(showMessage('Error', 'negative'));
          setTimeout(() => dispatch(hideMessage()), 2000);
        })
        .finally(() => setLoading(false));
    },
    [dispatch, triggerModal],
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getCompanies());
    dispatch(getBooks())
      .catch((error) => showMessage(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <MainLayout header="Books" selected="books" loading={loading} onAddClicked={triggerModal}>
      <GridView
        data={books}
        onClick={onClick}
        onEdit={(b) => {
          setShowModal(true);
          setEditingBook(b);
        }}
        onDelete={onDelete}
      />
      <CreateBookModal
        book={editingBook}
        onUnmount={() => setEditingBook({})}
        showModal={showModal}
        triggerModal={triggerModal}
        saveBook={saveBook}
        companies={companies}
      />
      <DeleteBookModal
        book={bookToDelete || {}}
        showModal={showDeleteModal}
        triggerModal={triggerDeleteModal}
        removeBook={removeBook}
      />
    </MainLayout>
  );
};

export default BooksPage;
