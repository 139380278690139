import React, { useCallback, useState } from 'react';
import { Container, Header, Form, Button, Input } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { useLocation, NavLink } from 'react-router-dom';
import url from 'url';
import { resetPassword } from '../../../redux/auth/actions';
import { showMessage, hideMessage } from '../../../redux/ui/actions';
import MainLayout from '../../layout/MainLayout';
import { useTranslation } from 'react-i18next';

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const { query } = url.parse(location.search, true);
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    (passwordPayload) => {
      console.log(location);
      console.log(query);
      setLoading(true);
      let resetPasswordObj = {
        Password: passwordPayload.Password,
        ConfirmPassword: passwordPayload.ConfirmPassword,
        Code: query.code,
        Email: query.username,
      };
      dispatch(resetPassword(resetPasswordObj))
        .then(() => {
          dispatch(showMessage('Password Changed', 'positive'));
          setTimeout(() => dispatch(hideMessage()), 2000);
        })
        .catch((e) => dispatch(showMessage(e)))
        .finally(() => setLoading(false));
    },
    [dispatch],
  );

  return (
    <MainLayout showAddButton={false} loading={loading}>
      <Container textAlign="center">
        <Header as="h2">Handbook Admin - Reset Password</Header>
        <ResetPasswordForm onSubmit={(passwordPayload) => onSubmit(passwordPayload)} />
      </Container>
    </MainLayout>
  );
};

const ResetPasswordForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [passwordPayload, setPassword] = useState({ Password: '', ConfirmPassword: '' });

  const onPasswordSubmit = () => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (passwordPayload.Password == '' || passwordPayload.ConfirmPassword == '') {
      dispatch(showMessage('Cannot be blank'));
    } else if (!re.test(passwordPayload.Password)) {
      dispatch(
        showMessage(
          'Password should be minimum eight  characters, at least one uppercase letter, one lowercase letter, one number and one special character',
        ),
      );
    } else if (passwordPayload.Password != passwordPayload.ConfirmPassword) {
      dispatch(showMessage('The value of Password and Confirm Password does not match'));
    } else {
      onSubmit(passwordPayload);
    }
  };
  return (
    <Container textAlign="center">
      <Form style={{ width: '600px', display: 'inline-block' }}>
        <Form.Field>
          <Input
            id="password"
            type="password"
            label="Password"
            value={passwordPayload.Password}
            onChange={(e) => setPassword({ ...passwordPayload, Password: e.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <Input
            id="confirm-password"
            type="password"
            label="Confirm Password"
            value={passwordPayload.ConfirmPassword}
            onChange={(e) => setPassword({ ...passwordPayload, ConfirmPassword: e.target.value })}
          />
        </Form.Field>

        <Button
          type="submit"
          onClick={() => {
            onPasswordSubmit();
          }}
        >
          {t('submit')}
        </Button>
      </Form>
      <br />
      <br />
      <div style={{ padding: '10px', textDecoration: 'underline' }}>
        <NavLink to={'/'}>Go to Login</NavLink>
      </div>
    </Container>
  );
};

export default ResetPasswordPage;
