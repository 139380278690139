import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Form, Container, Input, Select, TextArea } from 'semantic-ui-react';

import { getRole } from '../../../redux/auth/selectors';

const BookErrorValues = { name: null, companyId: null };

const CreateBookModal = ({
  book: data,
  showModal,
  triggerModal,
  onUnmount,
  saveBook,
  companies,
}) => {
  const [book, setBook] = useState(data);
  const [inputError, setInputError] = useState(BookErrorValues);
  const currentUserRole = useSelector((state) => getRole(state));

  const checkValidity = () => {
    const errors = {};
    let isValid = true;
    Object.keys(inputError).forEach((field) => {
      if (book[field] === undefined || book[field] === '') {
        errors[field] = 'This Field is required!';
        isValid = false;
      }
    });

    setInputError({ ...errors });
    return isValid;
  };

  useEffect(() => {
    setBook(data);
  }, [data]);

  const onChange = useCallback(({ value }, field) => setBook({ ...book, [field]: value }), [book]);

  if (companies && companies.length > 0 && !book.companyId) {
    book.companyId = companies[0].id;
  }
  return (
    <Modal open={showModal} onMount={() => setBook(data)} onUnmount={onUnmount}>
      <Modal.Header>{`${book.id ? 'Edit' : 'Create'} Book`}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Container style={{ paddingLeft: 10 }}>
            <Form>
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-first-name"
                  control={Input}
                  label="Book Name"
                  placeholder="Book Name"
                  disabled={currentUserRole === 'Admin'}
                  required
                  value={(book && book.name) || ''}
                  onChange={(_, value) => {
                    setInputError({ ...inputError, name: null });
                    onChange(value, 'name');
                  }}
                  error={inputError.name}
                />
                <Form.Field
                  id="form-input-control-first-name"
                  type="number"
                  control={Input}
                  label="Revision"
                  placeholder="Revision"
                  disabled={currentUserRole === 'Admin'}
                  value={(book && book.revision) || ''}
                  onChange={(_, value) => onChange(value, 'revision')}
                />

                <Form.Field
                  required
                  control={Select}
                  options={companies.map((c) => ({ key: c.id, text: c.name, value: c.id }))}
                  label={{ children: 'Company', htmlFor: 'form-select-control-gender' }}
                  placeholder="Company"
                  disabled={currentUserRole === 'Admin'}
                  value={book && (book.company || book.companyId)}
                  onChange={(_, value) => {
                    setInputError({ ...inputError, companyId: null });
                    onChange(value, 'companyId');
                  }}
                  search
                  searchInput={{ id: 'form-select-control-gender' }}
                  error={inputError.companyId}
                />
              </Form.Group>
              <Form.Checkbox
                inline
                label="Enabled"
                disabled={currentUserRole === 'Admin'}
                checked={book && book.enabled}
                onChange={(_, { checked }) => {
                  onChange({ value: checked }, 'enabled');
                }}
              />
              <Form.Field
                id="form-textarea-control-k"
                control={TextArea}
                label="Description"
                placeholder="Description"
                value={(book && book.description) || ''}
                onChange={(_, value) => onChange(value, 'description')}
              />
            </Form>
          </Container>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div>
          <Button
            content="Cancel"
            onClick={() => {
              triggerModal();
              setInputError(BookErrorValues);
            }}
          />
          <Button content="Save" positive onClick={() => checkValidity() && saveBook(book)} />
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default CreateBookModal;
