import React, { useState } from 'react';
import { Form, Button, Container, Input } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoginForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [user, setUser] = useState({ Email: '', Password: '' });
  return (
    <Container textAlign="center">
      <Form style={{ width: '600px', display: 'inline-block' }}>
        <Form.Field>
          <Input
            placeholder="Email"
            value={user.Email}
            onChange={(e, value) => setUser({ ...user, Email: e.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <Input
            placeholder="Password"
            value={useState.Password}
            type="password"
            onChange={(e) => setUser({ ...user, Password: e.target.value })}
          />
        </Form.Field>
        <Button type="submit" onClick={() => onSubmit(user)}>
          {t('submit')}
        </Button>
      </Form>
      <div style={{ padding: '10px', textDecoration: 'underline' }}>
        <NavLink to={'/forgot-password'}>{t('forgot_password')}</NavLink>
      </div>
    </Container>
  );
};

export default LoginForm;
