import React, { useCallback, useState } from 'react';
import { Container, Header, Form, Button, Input } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { forgotPassword } from '../../../redux/auth/actions';
import { showMessage, hideMessage } from '../../../redux/ui/actions';
import MainLayout from '../../layout/MainLayout';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    (email) => {
      setLoading(true);
      let emailPayload = {
        Email: email,
        CallbackUrl: `${window.location.origin}/admin/reset-password`,
      };
      dispatch(forgotPassword(emailPayload))
        .then(() => {
          dispatch(showMessage('Email Sent', 'positive'));
          setTimeout(() => dispatch(hideMessage()), 2000);
        })
        .catch((e) => dispatch(showMessage(e)))
        .finally(() => setLoading(false));
    },
    [dispatch],
  );

  return (
    <MainLayout showAddButton={false} loading={loading}>
      <Container textAlign="center">
        <Header as="h2">Forgot Password</Header>
        <ForgotPasswordForm onSubmit={(email) => onSubmit(email)} />
      </Container>
    </MainLayout>
  );
};

const ForgotPasswordForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const re = /\S+@\S+\.\S+/;

  return (
    <Container textAlign="center">
      <Form>
        <Form.Field>
          <Input
            placeholder="user@domain.com"
            label="Email"
            value={email}
            pattern={re}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Field>

        <Button
          type="submit"
          onClick={() => {
            if (email != '' && re.test(email)) onSubmit(email);
          }}
        >
          {t('submit')}
        </Button>
      </Form>
    </Container>
  );
};
export default ForgotPasswordPage;
