import React from 'react';
import { Grid, Dropdown } from 'semantic-ui-react';

import './styles.css';

const Book = ({ title, onClick, onEdit, onDelete }) => (
  <div className="book" onClick={onClick}>
    {title}
    <Dropdown
      text={' '}
      value={' '}
      icon="ellipsis vertical"
      className="iconMore"
      options={[
        { key: 0, text: 'Edit', value: 0, onClick: () => onEdit(), icon: 'edit' },
        { key: 1, text: 'Delete', value: 1, onClick: () => onDelete(), icon: 'trash' },
      ]}
    />
  </div>
);

const GridView = ({ data, onClick, onEdit, onDelete }) => (
  <div style={{ height: '98%', overflow: 'auto' }}>
    <Grid columns={6} container stackable textAlign="left">
      {data.map((book) => (
        <Grid.Column key={book.id} style={{ width: '200px', height: '150px', fontSize: '16px' }}>
          <Book
            title={book.name}
            onClick={() => onClick(book.id)}
            onDelete={() => onDelete(book)}
            onEdit={() => onEdit(book)}
          />
        </Grid.Column>
      ))}
    </Grid>
  </div>
);

export default GridView;
