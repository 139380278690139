import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getRole } from './redux/auth/selectors';

import CreateBook from './components/pages/CreateBook';
import Login from './components/pages/LoginPage';
import ForgotPasswordPage from './components/pages/ForgotPasswordPage';
import ResetPasswordPage from './components/pages/ResetPasswordPage';
import BooksPage from './components/pages/BooksPage';
import CompaniesPage from './components/pages/CompaniesPage';
import UsersPage from './components/pages/UsersPage';
import EditBookPage from './components/pages/EditBookPage';
import './i18n';

const ProtectedRoute = ({ children, ...rest }) => {
  // TODO: validate token's expiration or fetch user profile for token validation
  // instead of just checking for token's existance in state
  const isAuthenticated = useSelector((state) => state.auth.token);
  return (
    <Route
      {...rest}
      render={({ location }) => (isAuthenticated ? children : <Redirect to="/login" />)}
    />
  );
};

const App = () => {
  const role = useSelector((state) => getRole(state));
  return (
    <Router basename="/admin">
      <Switch>
        <Route exact path="/">
          <Redirect to="/books" />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/forgot-password">
          <ForgotPasswordPage />
        </Route>
        <Route path="/reset-password">
          <ResetPasswordPage />
        </Route>
        <ProtectedRoute exact path="/books">
          <BooksPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path="/books/:id"
          component={({ match }) => <EditBookPage id={match.params.id} />}
        />
        <ProtectedRoute exact path="/create">
          <CreateBook />
        </ProtectedRoute>
        <ProtectedRoute path="/companies">
          {role === 'SuperUser' ? <CompaniesPage /> : <Redirect to="/books" />}
        </ProtectedRoute>
        <ProtectedRoute path="/users">
          <UsersPage />
        </ProtectedRoute>
      </Switch>
    </Router>
  );
};

export default App;
