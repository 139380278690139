import React, { useState, useEffect } from 'react';
import { Container, Table, Menu, Icon, Button, Checkbox, Pagination } from 'semantic-ui-react';
import ReactDragListView from 'react-drag-listview/lib/index.js';

const TableView = ({
  data = [],
  columns,
  footer,
  actions,
  order,
  displayNumber,
  paginationNumber,
  optionalMapper,
  fieldsToMap,
  dragProps,
}) => {
  return (
    <Container>
      {order ? (
        <ReactDragListView {...dragProps}>
          <ApplicationTable
            data={data}
            columns={columns}
            footer={footer}
            actions={actions}
            order={order}
            paginationNumber={paginationNumber}
            displayNumber={displayNumber}
            optionalMapper={optionalMapper}
            fieldsToMap={fieldsToMap}
          />
        </ReactDragListView>
      ) : (
        <ApplicationTable
          data={data}
          columns={columns}
          footer={footer}
          actions={actions}
          order={order}
          paginationNumber={paginationNumber}
          displayNumber={displayNumber}
          optionalMapper={optionalMapper}
          fieldsToMap={fieldsToMap}
        />
      )}
    </Container>
  );
};

const ApplicationTable = ({
  data,
  columns,
  footer,
  actions,
  order,
  displayNumber,
  paginationNumber,
  optionalMapper,
  fieldsToMap,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const menuItems = [];

  for (let i = 0; i < paginationNumber; i++) {
    menuItems.push(<Menu.Item as="a">{i}</Menu.Item>);
  }
  return (
    <Table celled stackable color="blue">
      <Table.Header>
        <Table.Row>
          {order && data.length > 1 && <Table.HeaderCell>Order</Table.HeaderCell>}
          {Array.from(columns, ([_, value]) => (
            <Table.HeaderCell key={_}>{value}</Table.HeaderCell>
          ))}
          {actions && <Table.HeaderCell>Actions</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      {paginationNumber ? (
        <Table.Body>
          {data.length > 0 ? (
            data
              .slice((currentPage - 1) * displayNumber, currentPage * displayNumber - 1)
              .map((item, index) => (
                <Table.Row key={item.id}>
                  {order && data.length > 1 && (
                    <Table.Cell>
                      <a key={index} href="#">
                        Drag
                      </a>
                    </Table.Cell>
                  )}
                  {Array.from(columns, ([key]) => (
                    <Table.Cell key={key}>
                      {key === 'enabled' ? (
                        <Checkbox disabled checked={!!item[key]} />
                      ) : fieldsToMap.includes(key) ? (
                        optionalMapper(item[key])
                      ) : (
                        item[key]
                      )}
                    </Table.Cell>
                  ))}
                  {actions && (
                    <Table.Cell>
                      {actions.map(
                        (action) =>
                          action.show && (
                            <Button key={action.id} icon onClick={() => action.callback(item.id)}>
                              <Icon name={action.icon} color={action.color} />
                            </Button>
                          ),
                      )}
                    </Table.Cell>
                  )}
                </Table.Row>
              ))
          ) : (
            <Table.Row negative>
              <Table.Cell colSpan={columns.size + 1} textAlign="center">
                No Data
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      ) : (
        <Table.Body>
          {data.length > 0 ? (
            data.map((item, index) => (
              <Table.Row key={item.id}>
                {order && data.length > 1 && (
                  <Table.Cell>
                    <a key={index} href="#">
                      Drag
                    </a>
                  </Table.Cell>
                )}
                {Array.from(columns, ([key]) => (
                  <Table.Cell key={key}>
                    {key === 'enabled' ? <Checkbox disabled checked={!!item[key]} /> : item[key]}
                  </Table.Cell>
                ))}
                {actions && (
                  <Table.Cell>
                    {actions.map(
                      (action) =>
                        action.show && (
                          <Button key={action.id} icon onClick={() => action.callback(item.id)}>
                            <Icon name={action.icon} color={action.color} />
                          </Button>
                        ),
                    )}
                  </Table.Cell>
                )}
              </Table.Row>
            ))
          ) : (
            <Table.Row negative>
              <Table.Cell colSpan={columns.size + 1} textAlign="center">
                No Data
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      )}
      {footer && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="5">
              <Menu floated="right" pagination>
                <Pagination
                  activePage={currentPage}
                  totalPages={paginationNumber}
                  onPageChange={(e, d) => setCurrentPage(d.activePage)}
                />
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
};

export default TableView;
