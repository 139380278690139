import React from 'react';
import { Modal, Button, Container } from 'semantic-ui-react';

const DeleteBookModal = ({ book, showModal, triggerModal, removeBook }) => (
  <Modal open={showModal}>
    <Modal.Header>Delete Book</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <Container style={{ paddingLeft: 10 }}>
          {`Are you sure you want to delete ${book.name}`}
        </Container>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <div>
        <Button content="Cancel" onClick={triggerModal} />
        <Button content="Delete" negative onClick={() => removeBook(book.id)} />
      </div>
    </Modal.Actions>
  </Modal>
);

export default DeleteBookModal;
