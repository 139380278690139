import { SET_MESSAGE, HIDE_MESSAGE, SET_SECTION } from './actionTypes';

const initialState = {
  message: {
    show: false,
    message: '',
    type: '',
  },
  selectedSection: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: {
          show: true,
          message: action.payload.message,
          type: action.payload.type,
        },
      };
    case HIDE_MESSAGE:
      return {
        ...state,
        message: {
          show: false,
          message: '',
          type: false,
        },
      };
    case SET_SECTION:
      return {
        ...state,
        selectedSection: action.payload,
      };
    default:
      return state;
  }
};
