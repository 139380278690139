import { LOGIN, LOGOUT, FORGOT_PASSWORD, RESET_PASSWORD } from './actionTypes';

const initialState = {
  token: window.localStorage.getItem('token') || null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
      };
    case FORGOT_PASSWORD:
      return {
        message: action.payload,
      };
    case RESET_PASSWORD:
      return {
        message: action.payload,
      };

    default:
      return state;
  }
};
