import React from 'react';
import { Container, Form, Input, Select, Button, TextArea } from 'semantic-ui-react';

import MainLayout from '../../layout/MainLayout';

const CreateBook = () => {
  return (
    <MainLayout header="Book" showAddButton={false} selected="books">
      <Container style={{ paddingLeft: 10 }}>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-first-name"
              control={Input}
              label="Book Name"
              placeholder="Book Name"
            />
            <Form.Field
              control={Select}
              options={[
                { key: 'm', text: 'Dataverse', value: 'male' },
                { key: 'f', text: 'Apply', value: 'female' },
              ]}
              label={{ children: 'Company', htmlFor: 'form-select-control-gender' }}
              placeholder="Company"
              search
              searchInput={{ id: 'form-select-control-gender' }}
            />
          </Form.Group>
          <Form.Field
            id="form-textarea-control-k"
            control={TextArea}
            label="Desctiption"
            placeholder="Desctiption"
          />
          <Form.Field id="form-button-control-public" control={Button} content="Save" />
        </Form>
      </Container>
    </MainLayout>
  );
};

export default CreateBook;
