import React, { useState, useEffect, useCallback } from 'react';

import { Grid, Header, Button, Icon } from 'semantic-ui-react';

import Modal from '../Modal';
import TableView from '../TableView';

import PageInputFields from './PageInputFields';

import { createPage } from '../../../redux/api/actions';

const createActions = (closeModal, newPage, clearForm, checkValidity) => (
  <div>
    <Button
      content="Cancel"
      onClick={() => {
        closeModal();
        clearForm();
      }}
    />
    <Button
      content="Save"
      positive
      onClick={() => {
        checkValidity() && newPage(createPage) && closeModal();
      }}
    />
  </div>
);

const editActions = (closeModal, editPage, clearForm, checkValidity) => (
  <div>
    <Button
      content="Cancel"
      onClick={() => {
        closeModal();
        clearForm();
      }}
    />
    <Button
      content="Save"
      positive
      onClick={() => {
        checkValidity() && editPage() && closeModal();
      }}
    />
  </div>
);

const deleteActions = (closeModal, deletePage, clearForm) => (
  <div>
    <Button
      content="Cancel"
      onClick={() => {
        closeModal();
        clearForm();
      }}
    />
    <Button
      content="Delete"
      negative
      onClick={() => {
        closeModal();
        deletePage();
      }}
    />
  </div>
);

const addPageButton = (openModal, creating, checkImgCsv) => (
  <Button
    disabled={creating || checkImgCsv()}
    icon
    color="green"
    labelPosition="left"
    onClick={openModal}
  >
    <Icon name="add" />
    Add
  </Button>
);

const fieldsPerCase = {
  3: { pageType: null, name: null, filename: null },
  4: { url: null, name: null },
  6: { name: null, filename: null },
  7: { name: null, filename: null },
  8: { name: null, filename: null },
};

const PagesTable = ({
  data,
  type,
  onInputChange,
  newPage,
  editPage,
  deletePage,
  multipleInputChange,
  formFields,
  clearForm,
  setValuesOnEdit,
  downloadFile,
  editModal,
  setEditModal,
  deleteModal,
  setDeleteModal,
  creating,
  section,
}) => {
  const [inputError, setInputError] = useState(fieldsPerCase[type]);
  const [tableData, setTableData] = useState(data);

  const checkImgCsv = useCallback(() => {
    if (type !== 3 && type !== 8 && data.length >= 1) {
      return true;
    }
    if (type == 3 && data.length >= 1) {
      const fileType = data[0].filename?.split('.')[1];
      if (fileType == 'csv') {
        return true;
      }
    }
    return false;
  }, [type, data]);

  const checkValidity = () => {
    const errors = {};
    let isValid = true;

    Object.keys(inputError).forEach((field) => {
      if (formFields[field] === undefined || formFields[field] === '') {
        errors[field] = 'This Field is required!';
        isValid = false;
      }
    });

    setInputError({ ...errors });
    return isValid;
  };

  useEffect(() => {
    setInputError(fieldsPerCase[type]);
  }, [type]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...tableData];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setTableData(data);
    },
    handleSelector: 'a',
  };

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    // Moved insde because of UI error
    if (tableData !== data) {
      let itemOrder = null;
      tableData.forEach((item) => {
        itemOrder = itemOrder ? `${itemOrder},${item.id}` : item.id;
      });
      section.s = itemOrder;
    }
  }, [tableData]);

  return (
    <div style={{ marginBottom: 20 }}>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column verticalAlign="middle">
            <Header as="h4">Pages</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Modal
              header="Add new page"
              trigger={(open) => addPageButton(open, creating, checkImgCsv)}
              actions={(closeModal) => createActions(closeModal, newPage, clearForm, checkValidity)}
              onClose={clearForm}
            >
              <PageInputFields
                type={type}
                onInputChange={onInputChange}
                multipleInputChange={multipleInputChange}
                formFields={formFields}
                inputError={inputError}
                setInputError={setInputError}
              />
            </Modal>
            <Modal
              header="Edit Page"
              open={editModal}
              actions={() =>
                editActions(() => setEditModal(false), editPage, clearForm, checkValidity)
              }
              isControlled
            >
              <PageInputFields
                type={type}
                onInputChange={onInputChange}
                multipleInputChange={multipleInputChange}
                formFields={formFields}
                inputError={inputError}
                setInputError={setInputError}
              />
            </Modal>
            <Modal
              header="Delete Page"
              open={deleteModal}
              actions={() => deleteActions(() => setDeleteModal(false), deletePage, clearForm)}
              isControlled
            >
              <div>{`Are you sure you want to delete ${formFields.name}?`}</div>
            </Modal>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <TableView
            data={tableData}
            columns={
              new Map([
                ['id', 'Id'],
                ['name', 'Name'],
                type === 4 ? ['url', 'URL'] : ['filename', 'Filename'],
                ['enabled', 'Enabled'],
              ])
            }
            actions={[
              {
                id: 'download',
                callback: (id) => {
                  downloadFile(id);
                },
                icon: 'download',
                color: 'green',
                show: type !== 4,
              },
              {
                id: 'edit',
                callback: (id) => {
                  setValuesOnEdit(id);
                  setEditModal(true);
                },
                icon: 'edit',
                color: 'yellow',
                show: true,
              },
              {
                id: 'delete',
                callback: (id) => {
                  setValuesOnEdit(id);
                  setDeleteModal(true);
                },
                icon: 'trash',
                color: 'red',
                show: true,
              },
            ]}
            order
            dragProps={dragProps}
            footer={false}
          />
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default PagesTable;
