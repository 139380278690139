import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Select, Checkbox, Search } from 'semantic-ui-react';

import MainLayout from '../../layout/MainLayout';
import TableView from '../../general/TableView';
import {
  getUsers,
  createUser,
  editUser,
  deleteUser,
  getCompanies,
  getBooks,
} from '../../../redux/api/actions';
import { showMessage } from '../../../redux/ui/actions';
import { getRole, getUserId } from '../../../redux/auth/selectors';
import Modal from '../../general/Modal';
import './styles.css';

const NUM_USERS_DISPLAYED = 10;

const userErrorValues = {
  username: null,
  email: null,
  password: null,
  phoneNumber: null,
  companies: null,
};

const Dropdown = React.memo(
  ({ selectedEntries, allEntries = [], onChange, fieldName, width, error }) => {
    return (
      <Form.Field
        error={error}
        width={width}
        control={Select}
        placeholder={`Select ${fieldName}`}
        label={{ children: `${fieldName}`, htmlFor: 'form-select-control-companies' }}
        options={[...allEntries.map((c) => ({ key: c.id, value: c.id, text: c.name }))]}
        value={
          selectedEntries
            ? `${selectedEntries}`
                .split(',')
                .filter((s) => s)
                .map((s) => parseInt(s, 10))
            : [-1]
        }
        onChange={onChange}
        fluid
        multiple
        search
        selection
      />
    );
  },
);

const UsersPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showDeletModal, setShowDeleteModal] = useState(false);
  const [selected, setSelected] = useState(null);

  const [user, setUser] = useState({});
  const [inputError, setInputError] = useState(userErrorValues);
  const [searchedUsers, setSearchedUsers] = useState({});
  const [adminCompanies, setAdminCompanies] = useState([]);
  const [adminBooks, setAdminBooks] = useState([]);
  const [userAvailableBooks, setUserAvailableBooks] = useState([]);

  const allCompanies = useSelector((state) => Object.values(state.api.companies || {}));
  const allBooks = useSelector((state) => Object.values(state.api.books || {}));
  const users = useSelector((state) => Object.values(state.api.users || {}));

  const paginationNumber = Math.ceil(
    searchedUsers.length > 0
      ? searchedUsers.length / NUM_USERS_DISPLAYED
      : users.length / NUM_USERS_DISPLAYED,
  );

  const currentUserRole = useSelector((state) => getRole(state));
  const currentUserId = useSelector((state) => getUserId(state));

  const checkValidity = () => {
    const errors = {};
    let isValid = true;

    Object.keys(inputError).forEach((field) => {
      if (user[field] === undefined || user[field] === '') {
        errors[field] = 'This Field is required!';
        isValid = false;
      }
    });

    if (user.role === 'Admin' && (user.admin === undefined || user.admin === '')) {
      errors.admin = 'This Field is required!';
    }

    setInputError({ ...errors });
    return isValid;
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(getUsers()),
      dispatch(getCompanies()),
      dispatch(getBooks()),
    ]).finally((res) => setLoading(false));
  }, []);

  useEffect(() => {
    const relatedCompanies = allCompanies.filter(
      (c) =>
        user.companies && user.companies !== '' && user.companies.split(',').includes(`${c.id}`),
    );

    const relatedBooks = allBooks.filter(
      (b) =>
        user.companies &&
        user.companies !== '' &&
        user.companies.split(',').includes(`${b.companyId}`),
    );
    setAdminCompanies(relatedCompanies);
    setAdminBooks(relatedBooks);
    setUserAvailableBooks(relatedBooks);
  }, [user.companies]);

  useEffect(() => {
    if (user.admin && user.admin !== '') {
      const newUserAdmins = user.admin
        .split(',')
        .filter((c) => adminCompanies.find((ac) => ac.id == c))
        .join(',');

      setUser({ ...user, admin: newUserAdmins });
    }
  }, [adminCompanies]);

  useEffect(() => {
    if (user.bookAdmin && user.bookAdmin !== '') {
      const newUserBookAdmins = user.bookAdmin
        .split(',')
        .filter((c) => adminBooks.find((ac) => ac.id == c))
        .join(',');

      setUser({ ...user, bookAdmin: newUserBookAdmins });
    }
  }, [adminBooks]);

  const searchForUsers = (e, { value }) => {
    let searchResult = [];

    const regEx = new RegExp(value, 'i');
    setLoading(true);
    searchResult = users.filter(
      (u) => regEx.test(u.username) || regEx.test(u.email) || regEx.test(`${u.id}`),
    );
    setLoading(false);

    setSearchedUsers(searchResult);
  };

  const optionalMapper = (data = {}, fieldToReturn) => (fieldValueToMap) => {
    let foundFields;

    const spreadFields = fieldValueToMap && fieldValueToMap.split(',');

    if (spreadFields.length === 0) return null;
    if (Array.isArray(data)) {
      foundFields = spreadFields.map(
        (i) => data.find((obj) => parseInt(obj.id, 10) === parseInt(i, 10)).name,
      );
    } else {
      foundFields = spreadFields.map(
        (i) => data[parseInt(i, 10)] && data[parseInt(i, 10)][fieldToReturn],
      );
    }

    foundFields = foundFields.join(',');

    return foundFields;
  };

  const fieldsToMap = ['companies'];

  const actionDispatcher = () => {
    setShowAddModal(false);
    setLoading(true);
    dispatch(isEdit ? editUser(user) : createUser(user))
      .then(() => {
        dispatch(showMessage(`Successfully ${isEdit ? 'edited' : 'created'} user`, 'positive'));
        setIsEdit(false);
        setUser({});
      })
      .catch((message) => {
        dispatch(
          showMessage(`Failed to ${isEdit ? 'edit' : 'create'} user. ` + message, 'negative'),
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <MainLayout
      header="Users"
      selected="users"
      loading={loading}
      showAddButton={false}
      onAddClicked={() => setShowAddModal(true)}
      Search={
        <Search
          className="searchInput"
          placeholder="Search by username or email"
          loading={loading}
          onSearchChange={searchForUsers}
          showNoResults={searchedUsers.length === 0}
        />
      }
    >
      <TableView
        optionalMapper={optionalMapper(allCompanies, 'name')}
        fieldsToMap={fieldsToMap}
        displayNumber={NUM_USERS_DISPLAYED}
        paginationNumber={paginationNumber}
        footer
        data={(searchedUsers.length > 0 ? searchedUsers : users) || []}
        columns={
          new Map([
            ['username', 'Username'],
            ['email', 'Email'],
            ['companies', 'Company'],
            ['role', 'Role'],
          ])
        }
        actions={[
          {
            id: 'delete',
            callback: (id) => {
              setSelected(id);
              setShowDeleteModal(true);
            },
            icon: 'trash',
            color: 'red',
            show: true,
          },
          {
            id: 'edit',
            callback: (id) => {
              setIsEdit(true);
              setUser(users.find((u) => u.id === id) || {});
              setShowAddModal(true);
            },
            icon: 'edit',
            color: 'yellow',
            show: true,
          },
        ]}
      />
      <Modal
        header={isEdit ? 'Edit User' : 'Add User'}
        open={showAddModal}
        isControlled
        actions={(closeModal) => (
          <div>
            <Button
              content="Cancel"
              onClick={() => {
                setShowAddModal(false);
                setIsEdit(false);
                setUser({});
                setInputError(userErrorValues);
              }}
            />
            <Button
              content={isEdit ? 'Save' : 'Create'}
              positive
              onClick={() => {
                checkValidity() && actionDispatcher();
              }}
            />
          </div>
        )}
      >
        <Form autoComplete="off">
          <Form.Field>
            <Form.Input
              fluid
              label="Username"
              placeholder="Username"
              value={(user || {}).username || ''}
              onChange={(e, { value: username }) => {
                setInputError({ ...inputError, username: null });
                setUser({ ...user, username });
              }}
              error={inputError.username}
            />
          </Form.Field>
          {!isEdit && (
            <Form.Input
              label="Email"
              placeholder="Email"
              value={user.email || ''}
              onChange={(e, { value: email }) => {
                setInputError({ ...inputError, email: null });
                setUser({ ...user, email });
              }}
              error={inputError.email}
            />
          )}
          <Form.Input
            label="Phone Number"
            placeholder="Set a contanct phone number (optional)"
            value={user.phoneNumber || ''}
            onChange={(e, { value: phoneNumber }) => {
              setInputError({ ...inputError, phoneNumber: null });
              setUser({ ...user, phoneNumber });
            }}
            error={inputError.phoneNumber}
          />
          <Form.Input
            autoComplete="new-password"
            type="password"
            label="Password"
            placeholder={isEdit ? 'Set a new password if required (optional)' : 'Enter password'}
            value={user.password || ''}
            onChange={(e, { value: password }) => {
              setInputError({ ...inputError, password: null });
              setUser({ ...user, password });
            }}
            error={inputError.password}
          />
          {currentUserRole === 'SuperUser' && currentUserId === user.id ? (
            <Form.Field children="SuperUser" />
          ) : (
            <>
              <Dropdown
                selectedEntries={user.companies}
                allEntries={allCompanies}
                onChange={(e, { value, ...rest }) => {
                  setInputError({ ...inputError, companies: null });
                  setUser({ ...user, companies: value.join(',') });
                }}
                fieldName="Companies"
                error={inputError.companies}
              />
              {currentUserRole === 'SuperUser' && (
                <Form.Group widths={16}>
                  <Form.Field width={2}>
                    <Checkbox
                      disabled={currentUserId === user.id}
                      label="Admin"
                      checked={user.role === 'Admin'}
                      onChange={(e, d) => {
                        setUser({ ...user, role: d.checked === true ? 'Admin' : '', admin: '' });
                      }}
                    />
                  </Form.Field>
                  {user.role === 'Admin' && (
                    <>
                      <Dropdown
                        width={14}
                        selectedEntries={user.admin}
                        allEntries={adminCompanies}
                        onChange={(e, { value }) => {
                          setInputError({ ...inputError, admin: null });
                          setUser({ ...user, admin: value.join(',') });
                        }}
                        fieldName="in which Companies"
                        error={inputError.admin || null}
                      />
                      <Dropdown
                        width={14}
                        selectedEntries={user.bookAdmin}
                        allEntries={adminBooks}
                        onChange={(e, { value }) => {
                          setUser({ ...user, bookAdmin: value.join(',') });
                        }}
                        fieldName="in which Books"
                      />
                    </>
                  )}
                </Form.Group>
              )}
            </>
          )}
          <Dropdown
            selectedEntries={user.books}
            allEntries={userAvailableBooks}
            onChange={(e, { value, ...rest }) => {
              setInputError({ ...inputError, books: null });
              setUser({ ...user, books: value.join(',') });
            }}
            fieldName="Books"
            error={inputError.books}
          />
        </Form>
      </Modal>
      <Modal
        header="Delete User"
        open={showDeletModal}
        actions={(closeModal, page, clearForm) => (
          <div>
            <Button
              content="Cancel"
              onClick={() => {
                setShowDeleteModal(false);
                // clearForm();
              }}
            />
            <Button
              content="Delete"
              negative
              onClick={() => {
                if (selected) {
                  setLoading(true);
                  dispatch(deleteUser(selected)).then(() => {
                    setShowDeleteModal(false);
                    setLoading(false);
                  });
                }
              }}
            />
          </div>
        )}
        isControlled
      >
        <div>Are you sure you want to delete user ?</div>
      </Modal>
    </MainLayout>
  );
};

export default UsersPage;
