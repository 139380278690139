export const SET_BOOKS = 'SET_BOOKS';
export const SET_BOOK = 'SET_BOOK';
export const EDIT_BOOK = 'EDIT_BOOK';
export const REMOVE_BOOK = 'REMOVE_BOOK';
export const ADD_SECTION = 'ADD_SECTION';
export const EDIT_SECTION = 'EDIT_SECTION';
export const REMOVE_SECTION = 'REMOVE_SECTION';
export const SET_COMPANIES = 'SET_COMPANIES';
export const REMOVE_COMPANY = 'REMOVE_COMPANY';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const EDIT_PAGE = 'EDIT_PAGE';
export const REMOVE_PAGE = 'REMOVE_PAGE';
export const ADD_PAGE = 'ADD_PAGE';
export const SET_USERS = 'SET_USERS';
export const EDIT_USER = 'EDIT_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const GET_DOC_LIST = 'GET_DOC_LIST';
