import jwt_decode from 'jwt-decode';

const roleClaim = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
const emailaddress = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress';

export const getDecodedToken = (state) => (state.auth.token && jwt_decode(state.auth.token)) || {};

export const getRole = (state) => getDecodedToken(state)[roleClaim];

export const getUserId = (state) => getDecodedToken(state).sid;

export const getUserName = (state) => getDecodedToken(state)[emailaddress];

export const LoggedIn = (state) => Object.keys(getDecodedToken(state)).length !== 0;
