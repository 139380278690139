import React, { useCallback } from 'react';
import {
  Container,
  Menu,
  Segment,
  Grid,
  Header,
  Button,
  Icon,
  Message,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { logout } from '../../../redux/auth/actions';
import { hideMessage } from '../../../redux/ui/actions';

import { getRole, getUserName, LoggedIn } from '../../../redux/auth/selectors';

import applyLogo from '../../../styles/images/apply.png';

import './styles.css';

const ProtectedLink = ({ link, name, selected }) => {
  const isAuthenticated = useSelector((state) => state.auth.token);
  return isAuthenticated ? (
    <Menu.Item name={name} active={selected === link}>
      <Link to={`/${link}`}>{name}</Link>
    </Menu.Item>
  ) : null;
};

const MainLayout = ({
  children,
  selected,
  header,
  showAddButton = true,
  onAddClicked,
  loading,
  Search,
}) => {
  const dispatch = useDispatch();
  const { show: showMessage, message, type } = useSelector((state) => state.ui.message);
  const { t } = useTranslation();
  const history = useHistory();

  const role = useSelector((state) => getRole(state));
  const user = useSelector((state) => getUserName(state));
  const isLoggedIn = useSelector((state) => LoggedIn(state));

  const onLogout = useCallback(() => dispatch(logout()).then((res) => history.push('/login')), [
    dispatch,
    history,
  ]);
  return (
    <div style={{ height: '100vh' }}>
      <Menu pointing secondary>
        <Link to="/" className="logoImage">
          <img className="logoImage" src={applyLogo} alt="Apply" />
        </Link>
        <Container style={{ width: '85%' }}>
          <ProtectedLink name="Books" link="books" selected={selected} />
          {role === 'SuperUser' && (
            <ProtectedLink name="Companies" link="companies" selected={selected} />
          )}
          <ProtectedLink name="Users" link="users" selected={selected} />
          {isLoggedIn && (
            <Menu.Menu position="right" selected={selected}>
              <Menu.Item>
                <p>
                  LoggedIn As : <b>{user}</b>{' '}
                </p>
              </Menu.Item>
              <Menu.Item name="Logout" active={selected === 'logout'} onClick={onLogout} />
            </Menu.Menu>
          )}
        </Container>
      </Menu>
      <Container style={{ height: '92%', width: '85%' }}>
        <Segment.Group raised style={{ height: '100%' }}>
          <Segment style={{ minHeight: '5%' }}>
            {showMessage && (
              <Message
                positive={type === 'positive'}
                negative={type === 'negative'}
                onDismiss={() => dispatch(hideMessage())}
              >
                <Message.Header>{type === 'positive' ? t('success') : t('error')}</Message.Header>
                <p>{message}</p>
              </Message>
            )}
            <Grid columns={2} stackable>
              <Grid.Column>
                <Segment basic>
                  <Header textAlign="left" as="h4">
                    {header}
                  </Header>
                  {Search}
                </Segment>
              </Grid.Column>
              <Grid.Column>
                {showAddButton && (
                  <Segment basic textAlign="right">
                    <Button icon color="green" labelPosition="left" onClick={onAddClicked}>
                      <Icon name="add" />
                      Add
                    </Button>
                  </Segment>
                )}
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment
            style={{ paddingTop: 0 }}
            className={!showMessage ? 'childrenLayout' : 'withMessage'}
            padded
            basic
          >
            {loading ? (
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            ) : (
              children
            )}
          </Segment>
        </Segment.Group>
      </Container>
    </div>
  );
};

export default MainLayout;
