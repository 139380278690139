import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'semantic-ui-react';

import MainLayout from '../../layout/MainLayout';
import TableView from '../../general/TableView';

import {
  getCompanies,
  createCompany,
  editCompany,
  deleteCompany,
} from '../../../redux/api/actions';
import Modal from '../../general/Modal';

const companiesErrorValues = { name: null };

const CompaniesPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showDeletModal, setShowDeleteModal] = useState(false);
  const [company, setCompany] = useState({ name: '', description: '' });
  const [inputError, setInputError] = useState(companiesErrorValues);
  const [selected, setSelected] = useState(null);
  const companies = useSelector((state) => Object.values(state.api.companies));

  const checkValidity = () => {
    const errors = {};
    let isValid = true;

    Object.keys(inputError).forEach((field) => {
      if (company[field] === undefined || company[field] === '') {
        errors[field] = 'This Field is required!';
        isValid = false;
      }
    });

    setInputError({ ...errors });
    return isValid;
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getCompanies()).finally((res) => setLoading(false));
  }, []);

  const actionDispather = () => {
    setLoading(true);
    dispatch(isEdit ? editCompany(company) : createCompany(company))
      .then(() => {
        setShowAddModal(false);
        setIsEdit(false);
        setCompany({ name: '', description: '' });

        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <MainLayout
      header="Companies"
      selected="companies"
      loading={loading}
      onAddClicked={() => setShowAddModal(true)}
    >
      <TableView
        data={companies}
        columns={
          new Map([
            ['name', 'Name'],
            ['description', 'Description'],
          ])
        }
        actions={[
          {
            id: 'delete',
            callback: (id) => {
              setSelected(id);
              setShowDeleteModal(true);
            },
            icon: 'trash',
            color: 'red',
          },
          {
            id: 'edit',
            callback: (id) => {
              setIsEdit(true);
              setCompany(companies.find((c) => c.id === parseInt(id, 10)));
              setShowAddModal(true);
            },
            icon: 'edit',
            color: 'yellow',
          },
        ]}
      />
      <Modal
        header={isEdit ? 'Edit Company' : 'Add Company'}
        open={showAddModal}
        isControlled
        actions={(closeModal) => (
          <div>
            <Button
              content="Cancel"
              onClick={() => {
                setShowAddModal(false);
                setIsEdit(false);
                setCompany({ name: '', description: '' });
                setInputError(companiesErrorValues);
              }}
            />
            <Button
              content={isEdit ? 'Save' : 'Create'}
              positive
              onClick={() => {
                checkValidity() && actionDispather();
              }}
            />
          </div>
        )}
      >
        <Form>
          <Form.Field>
            <Form.Input
              fluid
              label="Name"
              placeholder="Name"
              value={company.name}
              onChange={(e, { value: name }) => {
                setInputError({ ...inputError, name: null });
                setCompany({ ...company, name });
              }}
              error={inputError.name}
            />
          </Form.Field>
          <Form.TextArea
            label="Description"
            placeholder="Description"
            value={company.description}
            onChange={(e, { value: description }) => setCompany({ ...company, description })}
          />
        </Form>
      </Modal>
      <Modal
        header="Delete Page"
        open={showDeletModal}
        actions={(closeModal, page, clearForm) => (
          <div>
            <Button
              content="Cancel"
              onClick={() => {
                setShowDeleteModal(false);
                // clearForm();
              }}
            />
            <Button
              content="Delete"
              negative
              onClick={() => {
                if (selected) {
                  setLoading(true);
                  dispatch(deleteCompany(selected)).then(() => {
                    setShowDeleteModal(false);
                    setLoading(false);
                  });
                }
              }}
            />
          </div>
        )}
        isControlled
      >
        <div>Are you sure you want to delete company ?</div>
      </Modal>
    </MainLayout>
  );
};

export default CompaniesPage;
