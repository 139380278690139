import {
  SET_BOOKS,
  SET_BOOK,
  EDIT_BOOK,
  REMOVE_BOOK,
  ADD_SECTION,
  EDIT_SECTION,
  REMOVE_SECTION,
  SET_COMPANIES,
  REMOVE_COMPANY,
  EDIT_COMPANY,
  EDIT_PAGE,
  REMOVE_PAGE,
  ADD_PAGE,
  SET_USERS,
  EDIT_USER,
  REMOVE_USER,
} from './actionTypes';

const initialState = {
  books: [],
  companies: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOKS:
      return {
        ...state,
        books: action.payload.reduce((acc, book) => ({ ...acc, [book.id]: book }), {}),
      };
    case SET_BOOK:
      return {
        ...state,
        books: { ...state.books, [action.payload.id]: action.payload },
      };
    case EDIT_BOOK: {
      const book = action.payload;
      return {
        ...state,
        books: { ...state.books, [book.id]: book },
      };
    }
    case REMOVE_BOOK: {
      const { id } = action.payload;
      const { books } = state;
      delete books[id];
      return {
        ...state,
        books,
      };
    }
    case ADD_SECTION: {
      const { bookId, sectionId } = action.payload;
      return {
        ...state,
        books: {
          ...state.books,
          [bookId]: {
            ...state.books[bookId],
            sections: [
              ...state.books[bookId].sections,
              { ...action.payload.section, i: sectionId },
            ],
          },
        },
      };
    }
    case EDIT_SECTION: {
      const { bookId, section } = action.payload;
      const { sections } = state.books[bookId];
      const index = sections.findIndex((s) => s.i === parseInt(action.payload.section.i, 10));
      sections[index] = section;
      return {
        ...state,
        books: {
          ...state.books,
          [bookId]: {
            ...state.books[bookId],
            sections,
          },
        },
      };
    }
    case REMOVE_SECTION: {
      const { bookId, sectionId } = action.payload;
      const { sections } = state.books[bookId];
      return {
        ...state,
        books: {
          ...state.books,
          [bookId]: {
            ...state.books[bookId],
            sections: sections.filter((s) => s.i !== sectionId),
          },
        },
      };
    }
    case ADD_PAGE: {
      const { page, bookId, pageId } = action.payload;
      const id = parseInt(bookId, 10);
      const newPage = {
        id: parseInt(pageId, 10),
        name: page.name,
        url: page.url,
        docNo: page.docNo,
        revNo: page.revNo,
        pageType: page.pageType,
        config: page.config,
        enabled: page.enabled,
        filename: page.filename,
        parentSections: page.parentSections,
      };
      return {
        ...state,
        books: {
          ...state.books,
          [id]: {
            ...state.books[id],
            pages: [...state.books[id].pages, newPage],
          },
        },
      };
    }
    case EDIT_PAGE: {
      const { page, bookId } = action.payload;
      const id = parseInt(bookId, 10);
      const { pages } = state.books[id];
      const index = state.books[id].pages.findIndex((p) => p.i === parseInt(page.id, 10));
      pages[index] = {
        ...pages[index],
        name: page.name,
        url: page.url,
        docNo: page.docNo,
        revNo: page.revNo,
        pageType: page.pageType,
        config: page.config,
        enabled: page.enabled,
        filename: page.filename,
      };
      return {
        ...state,
        books: {
          ...state.books,
          [id]: {
            ...state.books[id],
            pages,
          },
        },
      };
    }
    case REMOVE_PAGE: {
      const { page, bookId } = action.payload;
      const id = parseInt(bookId, 10);
      const { pages } = state.books[id];
      return {
        ...state,
        books: {
          ...state.books,
          [id]: {
            ...state.books[id],
            pages: pages.filter((p) => p.id !== parseInt(page.id, 10)),
          },
        },
      };
    }
    case SET_COMPANIES:
      return {
        ...state,
        companies: action.payload.reduce((acc, company) => ({ ...acc, [company.id]: company }), {}),
      };
    case REMOVE_COMPANY: {
      const { companyId } = action.payload;
      const { companies } = state;
      delete companies[companyId];
      return {
        ...state,
        companies,
      };
    }
    case EDIT_COMPANY: {
      const { company } = action.payload;
      return {
        ...state,
        companies: {
          ...state.companies,
          [company.id]: company,
        },
      };
    }
    case SET_USERS:
      return {
        ...state,
        users: action.payload.reduce((acc, user) => ({ ...acc, [user.id]: user }), {}),
      };
    case REMOVE_USER: {
      const { userId } = action.payload;
      const { users } = state;
      delete users[userId];
      return {
        ...state,
        users,
      };
    }
    case EDIT_USER: {
      const { user } = action.payload;
      return {
        ...state,
        users: {
          ...state.users,
          [user.id]: user,
        },
      };
    }
    default:
      return state;
  }
};
