import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Divider, Form, Checkbox, Select, Header, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './style.css';
import { selectAllSections } from '../../../redux/api/selectors';

import WarningModal from '../WarningModal';
import sectionTypes from '../../../constants/sectionTypes';

import PagesTable from './PagesTable';
import TableView from '../TableView';

import { showMessage } from '../../../redux/ui/actions';
import { createPage, editPage, deletePage, downloadFile } from '../../../redux/api/actions';

const typeToFileEndpoint = {
  0: 'getImg',
  1: 'getCSV',
  2: 'getPdfToken',
  5: 'getVideo',
  6: 'getSearchablePage'
};

const Dropdown = React.memo(({ parentSections, type, sections, onChange }) => {
  return (
    <Form.Field
      control={Select}
      placeholder="Parent Sections"
      label={{ children: 'Parent Sections', htmlFor: 'form-select-control-sectiontype' }}
      options={sections.map(sn => ({ key: sn.id, value: sn.id, text: sn.name }))}
      value={`${parentSections}`
        .split(',')
        .filter(s => s)
        .map(s => parseInt(s, 10))}
      onChange={onChange}
      disabled={(type || {}).t === 0}
      fluid
      multiple
      search
      selection
    />
  );
});

const validateFields = (section, setFieldError) => {
  const errorObj = {};
  let isValid = true;

  if (section.n === undefined || section.n === '') {
    errorObj.n = 'This Field is required!';
    isValid = false;
  }
  if (section.t === undefined || section.t === '' || section.t === null) {
    errorObj.t = 'This Field is required!';
    isValid = false;
  }

  setFieldError({ ...errorObj });

  return isValid;
};

const SectionForm = ({
  section: data,
  pages,
  subSections,
  onSubmit,
  creating,
  bookId,
  loading,
  setLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [section, setSection] = useState(data);
  const [formFields, setFormFields] = useState({});
  const [fieldError, setFieldError] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [sectionTableData, setSectionTableData] = useState(subSections);

  const allSections = useSelector(state => selectAllSections(state, bookId));
  const dispatchAction = (action, actionName) => {
    setLoading(true);
    dispatch(action())
      .then(() => dispatch(showMessage(t(`${actionName}_positive`), 'positive')))
      .catch(err => dispatch(showMessage(t(`${actionName}_negative`), 'negative')))
      .finally(() => setLoading(false));
    return true;
  };
  const clearForm = () => {
    setFormFields({ enabled: true, bookId, parentSections: section.i });
  };

  useEffect(() => {
    setSection(data);
    setFormFields({ enabled: true, bookId, parentSections: data.i });
  }, [data, bookId]);

  const onInputChange = useCallback(
    field => {
      setFormFields({ ...formFields, [field.name]: field.value });
    },
    [formFields],
  );

  const multipleInputChange = useCallback(
    fields => {
      let fieldsObject = {};
      fields.forEach(field => {
        fieldsObject = { ...fieldsObject, [field.name]: field.value };
      });
      setFormFields({ ...formFields, ...fieldsObject });
    },
    [formFields],
  );

  const setValuesOnEdit = useCallback(
    id => {
      const selectedPage = pages.find(page => page.id === id);

      setFormFields({ ...selectedPage });
    },
    [pages],
  );

  const downloadAction = useCallback(
    id => {
      const selectedPage = pages.find(page => page.id === id);

      dispatch(
        downloadFile(
          typeToFileEndpoint[selectedPage.pageType],
          selectedPage.filename,
          selectedPage.url ?? selectedPage.filename,
        ),
      );
    },
    [pages, dispatch],
  );

  const isRootFirstChild = useMemo(() => creating && section.t === sectionTypes.Root, [
    section,
    creating,
  ]);

  const onDropdownChange = useCallback(
    (e, { value }) => setSection({ ...section, ps: value.join(',') }),
    [section],
  );

  const isPageSectionVisible =
    section.t === 3 || section.t === 4 || section.t === 6 || section.t === 7 || section.t === 8;

  const dragPropsSectionTable = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...sectionTableData];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setSectionTableData(data);
    },
    handleSelector: 'a',
  };

  useEffect(() => {
    setSectionTableData(subSections);
  }, [subSections]);

  useEffect(() => {
    // Remove the refresh function and moved the logic inside useEffect.Test functionality
    if (subSections !== sectionTableData) {
      let itemOrder = null;
      sectionTableData.forEach(item => {
        itemOrder = itemOrder ? `${itemOrder},${item.id}` : item.id;
      });
      section.s = itemOrder;
    }
  }, [sectionTableData]);

  return (
    <div>
      <Header>{`${creating ? 'Create a new' : 'Edit'} Section`}</Header>
      <Divider />
      <Form>
        <WarningModal
          open={showWarning}
          onClick={() => setShowWarning(false)}
          sectionType={Object.keys(sectionTypes).find(name => sectionTypes[name] === section.t)}
        />
        <Form.Group>
          <Dropdown
            sections={allSections}
            parentSections={section.ps}
            type={section.t}
            onChange={onDropdownChange}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            value={section.n || ''}
            onChange={(e, { value }) => {
              setFieldError({ ...fieldError, n: null });
              setSection({ ...section, n: value });
            }}
            fluid
            label="Section Name"
            placeholder="Section Name"
            error={fieldError.n || null}
          />
          <Form.Field
            error={fieldError.t || null}
            control={Select}
            options={[
              { text: 'Select Page type', selected: true, disabled: true, value: -1 },
              ...Object.keys(sectionTypes).map(key => ({
                key: sectionTypes[key],
                text: key,
                value: sectionTypes[key],
                disabled:
                  sectionTypes[key] === sectionTypes.Swipe ||
                  sectionTypes[key] === sectionTypes.Root,
              })),
            ]}
            disabled={section.t === sectionTypes.Swipe || section.t === sectionTypes.Root}
            onChange={(e, { value }) => {
              if (pages.length) {
                setShowWarning(true);
              } else {
                setFieldError({ ...fieldError, t: null });
                setSection({ ...section, t: value });
              }
            }}
            value={isRootFirstChild ? sectionTypes.Swipe : section.t || -1}
            label={{ children: 'Section Type', htmlFor: 'form-select-control-sectiontype' }}
            placeholder="Section Type"
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Field>
            <Checkbox
              label="Enabled"
              checked={section.e}
              onChange={() => setSection({ ...section, e: !section.e })}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Button onClick={() => validateFields(section, setFieldError) && onSubmit(section)}>
            Save
          </Form.Button>
        </Form.Group>
        <Divider section />
        <Segment basic>
          {isPageSectionVisible && (
            <PagesTable
              loading={loading}
              data={pages}
              type={section.t}
              onInputChange={onInputChange}
              newPage={() => dispatchAction(() => createPage(formFields, bookId), 'createPage')}
              editPage={() => dispatchAction(() => editPage(formFields, bookId), 'editPage')}
              deletePage={() => dispatchAction(() => deletePage(formFields, bookId), 'deletePage')}
              multipleInputChange={multipleInputChange}
              formFields={formFields}
              clearForm={clearForm}
              setValuesOnEdit={setValuesOnEdit}
              downloadFile={downloadAction}
              editModal={editModal}
              setEditModal={setEditModal}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              creating={creating}
              section={section}
            />
          )}
          {!isPageSectionVisible && sectionTableData.length > 0 && (
            <div>
              <Header as="h4">Sections</Header>
              <TableView
                data={sectionTableData}
                columns={
                  new Map([
                    ['id', 'Id'],
                    ['name', 'Section Name'],
                  ])
                }
                order
                dragProps={dragPropsSectionTable}
                footer={false}
              />
            </div>
          )}
        </Segment>
      </Form>
    </div>
  );
};

export default SectionForm;
