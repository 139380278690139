import React, { useCallback, useState } from 'react';
import { Container, Header } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LoginForm from '../../general/LoginForm';
import { login } from '../../../redux/auth/actions';
import { showMessage, hideMessage } from '../../../redux/ui/actions';
import MainLayout from '../../layout/MainLayout';

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onLogin = useCallback(
    (user) => {
      setLoading(true);
      dispatch(login(user))
        .then(() => {
          history.push('./books');
          dispatch(hideMessage());
        })
        .catch((e) => dispatch(showMessage(e)))
        .finally(() => setLoading(false));
    },
    [dispatch, history],
  );

  return (
    <MainLayout showAddButton={false} loading={loading}>
      <Container textAlign="center">
        <Header as="h2">Handbook Admin</Header>
        <LoginForm onSubmit={(user) => onLogin(user)} />
      </Container>
    </MainLayout>
  );
};
export default LoginPage;
