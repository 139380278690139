import axios from 'axios';
import { LOGIN, LOGOUT, FORGOT_PASSWORD, RESET_PASSWORD } from './actionTypes';
import { environment } from '../../environment/Environment';

export const login = (user) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${environment.host.base}/v3/admin/getToken`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: user,
    })
      .then((res) => res.data)
      .then(({ token, message }) => {
        if (token) {
          dispatch({ type: LOGIN, payload: token });
          window.localStorage.setItem('token', token);
          resolve();
        }
        reject(message);
      })
      .catch(({ message }) => reject(message));
  });

export const logout = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      window.localStorage.removeItem('token');
      dispatch({ type: LOGOUT });
      resolve();
    } catch ({ message }) {
      reject(message);
    }
  });
export const forgotPassword = (emailPayload) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${environment.host.base}/v3/admin/forgotPassword`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: emailPayload,
    })
      .then((res) => res.data)
      .then(({ message }) => {
        dispatch({ type: FORGOT_PASSWORD, payload: message });
        resolve();
      })
      .catch(({ message }) => reject(message));
  });

export const resetPassword = (resetPasswordObj) => (dispatch) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${environment.host.base}/v3/admin/resetPassword`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: resetPasswordObj,
    })
      .then((res) => res.data)
      .then(({ message }) => {
        dispatch({ type: RESET_PASSWORD, payload: message });
        resolve();
      })
      .catch(({ message }) => reject(message));
  });
